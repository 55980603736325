<template>
  <div class="supper">
    <div class="holder">
      <!-- <AuthNavbar /> -->
      <!-- <div class="mobile-logo-holder">
        <div class="blue-back-logo">
          <img src="../assets/images/logo-header.svg" alt="" />
        </div>
        <div class="shelta-mobile-logo">
          <img src="../assets/images/logo1.svg" alt="" />
        </div>
      </div> -->
      <div class="login-body">
        <div class="login-wrap">
          <div class="">
            <div class="logo_holder">
              <img src="../assets/images/logo-yellow.svg" alt="logo" />
            </div>
            <hr />
          </div>
          <div class="container">
            <div class="my-card-body">
              <div class="login-header">
                <h1>Register</h1>
                <p>Sign up for free and gain exclusive access</p>
              </div>
              <form>
                <div class="form-group">
                  <div class="orgInd">
                    <button
                      @click.prevent="userType = 'individual'"
                      class="btn btn-block"
                      :class="[userType == 'individual' ? 'active_btn' : '']"
                    >
                      Individual
                    </button>
                    <button
                      @click.prevent="userType = 'organisation'"
                      class="btn btn-block"
                      :class="[userType == 'organisation' ? 'active_btn' : '']"
                    >
                      Organization
                    </button>
                  </div>
                </div>
                <div
                  class="form-group email-group"
                  v-if="userType == 'organisation'"
                >
                  <label for="email">Organization Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    id="name"
                    placeholder="Organization Name"
                    v-model="fname"
                  />
                </div>
                <div
                  class="form-group email-group"
                  v-if="userType == 'individual'"
                >
                  <label for="email">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="fname"
                    id="fname"
                    placeholder="First Name"
                    v-model="fname"
                  />
                </div>
                <div
                  class="form-group email-group"
                  v-if="userType == 'individual'"
                >
                  <label for="email">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="lname"
                    id="lname"
                    placeholder="Last Name"
                    v-model="lname"
                  />
                </div>
                <div class="form-group email-group">
                  <label for="email">Email Address</label>
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Email address"
                    autocomplete="on"
                    v-model="email"
                  />
                  <i
                    class="fa fa-envelope email-icon"
                    id="emaail"
                    aria-hidden="true"
                  ></i>
                  <!-- <input
                    type="email"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.email.$model,
                      'is-valid': !$v.email.$invalid,
                    }"
                    name="email"
                    id="email"
                    placeholder="Email address"
                    v-model.trim="$v.email.$model"
                    autocomplete="on"
                  /> -->
                  <!-- <div class="invalid-feedback">
                    <span v-if="!$v.email.required">Email is required</span>
                  </div> -->
                </div>
                <div class="form-group password-group">
                  <label for="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    class="form-control"
                    id="password"
                    placeholder="Password"
                    v-model="password"
                    autocomplete="on"
                  />
                  <i
                    class="fa fa-lock password-icon"
                    id="passwordVisibility"
                    aria-hidden="true"
                    @click="toggleLock()"
                  ></i>
                </div>
                <div class="form-group  password-group2">
                  <label for="confirmPassword">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    class="form-control"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    v-model="confirmPassword"
                    autocomplete="on"
                  />
                  <i
                    class="fa fa-lock password-icon2"
                    id="passwordVisibility2"
                    aria-hidden="true"
                    @click="toggleLock2()"
                  ></i>
                </div>
                <div class="form-group email-group">
                  <label for="code">Invite Code</label>
                  <input
                    type="text"
                    class="form-control"
                    name="code"
                    id="code"
                    placeholder="Enter invite code"
                    v-model="refereecode"
                  />
                </div>
                <div class="form-group form-check mb-3 mt-4 p-checkbox">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="ispartner"
                    true-value="YES"
                    false-value="NO"
                  />
                  <label class="form-check-label">Are you a partner?</label>
                </div>
                <div v-if="errors.length">
                  <p class="errorHeader">Please correct the error(s):</p>
                  <ul class="error-ul">
                    <li
                      v-for="error in errors"
                      :key="error.error"
                      class="errorMessage"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </div>
                <div class="form-group">
                  <button
                    @click.prevent="signup()"
                    class="btn login-btn btn-block"
                  >
                    CREATE MY ACCOUNT
                    <i
                      class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                      v-if="loader"
                    ></i>
                  </button>
                  <!-- <button
                    :disabled="!isComplete"
                    @click.prevent="signup()"
                    class="btn login-btn btn-block"
                  >
                    CREATE MY ACCOUNT
                  </button> -->
                </div>

                <div class="form-group d-none">
                  <div class="or">
                    <hr />
                    <span>or</span>
                    <hr />
                  </div>
                </div>
                <div class="form-group d-none">
                  <button class="btn btn-block google_btn">
                    <img src="../assets/images/google-G.svg" alt="google" />
                    Continue with Google
                  </button>
                </div>
                <div class="terms-conditions">
                  <p>
                    By clicking the “Create My Account” button, you agree to
                    Shelta’s
                    <span class="tc" data-toggle="modal" data-target="#termsc"
                      >terms & conditions.</span
                    >
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div class="have-account">
            <p>
              <router-link class="login-router" to="/login">
                Already have an account?
                <span class="register-span">Login</span>
              </router-link>
            </p>
          </div>
        </div>
        <div class="whatsapp-dialer-icon-holder d-none">
          <img src="../assets/images/whatsapp-icon.svg" alt="whatsapp" />
          <img src="../assets/images/phone-handle.svg" alt="call us" />
        </div>
      </div>

      <!-- Modal terms and condition starts -->
      <div
        class="modal fade"
        id="termsc"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" style="margin-bottom: 40px;">
            <div class="terms-holder">
              <h4>User's Terms & Condtions</h4>
              <h6>
                The following Terms and Conditions are applicable to every user
                of Shelta Application/Website. Please take out few minutes to
                read the following Terms and Conditions carefully. The use of
                this website/application indicates that you agree to be bound by
                the terms and condition, and you will follow the rules. If you
                do not agree to any of the following terms and condition then,
                please do not use this website/application. These Terms and
                Conditions May Change without any prior notice, the right to
                change or update the terms and conditions of this website is
                reserved by Shelta. As a user of the website/application,
                following any such amendments constitutes your agreement to be
                bound and follow the terms and conditions as amended. For such
                reasons, we motivate our users to read the terms and conditions
                carefully whenever they use this website/application.
              </h6>
              <div class="terms-body">
                <!-- <h5>Disclaimer</h5> -->
                <p>
                  Terms and Conditions of Use. These Terms and Conditions of Use
                  apply to information on www.myshelta.com and MyShelta mobile
                  application including all documents, links, data, information,
                  advertisements, policies, content, functions and other
                  materials and services contained on the website. This Website
                  may contain Content not owned by Shelta which may be subject
                  to separate terms and conditions. This Website/application is
                  provided by Shelta as a service to its customers for
                  informational purposes and for personal use
                </p>
                <h5>Changes to these Terms.</h5>
                <p>
                  This Website/Application may contain technical inaccuracies or
                  typographical errors or omissions. Shelta is not responsible
                  for inaccuracies, typographical errors, omissions, product
                  information or advertising. Shelta reserves the right, at its
                  discretion, to change, modify, add or remove all or portions
                  of these Terms at any time. Your continued use of this
                  Website/Application will indicate acceptance by you of such
                  rules, changes or modifications.
                </p>
                <h5>Links To Third Party Sites.</h5>
                <p>
                  This Website/Application may contain links to certain websites
                  owned, operated or controlled by third-parties (“Third Party
                  Sites”). Shelta does not control and is not responsible for
                  the Content of such Third Party Sites, or any changes or
                  updates to such sites. Links to Third Party Sites are provided
                  to you only as a convenience, for information purposes only,
                  and their inclusion does not imply any endorsement,
                  association, approval or warranty by Shelta of such Third
                  Party Sites or any of the Content contained therein. Access to
                  any Third Party Sites shall be at your own risk.
                </p>
                <h5>Restrictions On Use Of Content.</h5>
                <p>
                  Unless otherwise noted, all Content contained on this Website
                  is protected by copyrights, trademarks and/or other
                  intellectual properties laws. No Content may be copied,
                  reproduced, republished, uploaded, posted, transmitted or
                  distributed in any way, except that you may download, display
                  and print one copy of the materials presented on this
                  Website/Application on a single computer for your personal,
                  non-commercial use only. The use of any Content from this
                  Website/Application on any other internet, intranet, web or
                  other site or computer environment is prohibited.
                </p>
                <h5>Additional Terms.</h5>
                <p>
                  Additional terms and conditions may apply to certain portions
                  of this Website. By using this Website you agree to abide and
                  be bound by such additional terms and conditions as well as
                  all applicable laws.
                </p>
                <h5>Trademarks.</h5>
                <p>
                  The trademarks, logos and service marks displayed on this
                  Website are registered trademarks of Shelta. Nothing contained
                  on this Website/Application should be construed as granting
                  any license or right to use any of the trademarks without the
                  written permission of Shelta.
                </p>
                <h5>Communications</h5>
                <p>
                  No right of confidentiality shall apply to any inquiries or
                  other communications made to SHELTA PANACEA LIMITED through
                  this Website/Application and Shelta shall have no obligation
                  to protect Communications from disclosure to third parties.
                </p>
                <h5>DISCLAIMER</h5>
                <p>
                  SHELTA STRIVES TO ENSURE THAT THE CONTENTS OF THIS
                  WEBSITE/APPLICATION ARE ACCURATE AND RELIABLE. HOWEVER, ERRORS
                  AND/OR OMISSIONS MAY SOMETIMES OCCUR. AS A RESULT, THIS
                  WEBSITE/APPLICATION AND ITS CONTENTS ARE PROVIDED ON AN
                  “AS-IS” BASIS. SHELTA MAKES NO REPRESENTATIONS OR WARRANTIES
                  OF ANY KIND REGARDING THIS WEBSITE/APPLICATION, ITS CONTENT
                  AND/OR THE RESULTS THAT MAY BE OBTAINED FROM USE OF THIS
                  WEBSITE/APPLICATION. SHELTA HEREBY DISCLAIMS ANY AND ALL
                  WARRANTIES, INCLUDING WITHOUT LIMITATION, WARRANTIES OF
                  MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE,
                  WARRANTIES AGAINST INFRINGEMENT AND TITLE, AND WARRANTIES THAT
                  THIS WEBSITE/APPLICATION AND THE CONTENT WILL BE
                  UNINTERRUPTED, TIMELY, SECURE OR FREE OF ERRORS OF VIRUSES.
                  SHELTA IS NOT RESPONSIBLE FOR THE CORRECTNESS, ACCURACY,
                  COMPLETENESS OR RELIABILITY OF THIS WEBSITE/APPLICATION OR ANY
                  OF ITS CONTENT.
                </p>
                <h5>Limitation Of Liability.</h5>
                <p>
                  In no event shall Shelta or any of its respective officers,
                  directors, licensors, employees or agents be liable, whether
                  in contract, tort, strict liability or otherwise, for any
                  direct, indirect, punitive, special, incidental, consequential
                  or indirect loss, injury or damages (including, without
                  limitation, lost profits) arising out of or in connection with
                  the use of this Website/Application or its Content.
                </p>
                <h5>Jurisdiction</h5>
                <p>
                  The Content of this Website/Application is presented solely
                  for informational use in Nigeria. Shelta makes no
                  representation that this Website/Application or its Content is
                  appropriate or available for use in other jurisdictions. This
                  Website is not intended for distribution to, or use by, any
                  person or entity, or any jurisdiction or country where such
                  distribution or use would be contrary to local law or
                  regulation or would subject Shelta or any of its affiliates to
                  any liability.
                </p>
                <h5>Governing Law.</h5>
                <p>
                  These Terms shall be governed by and construed in accordance
                  with the laws of the Federal Republic of Nigeria. You agree
                  that any action at law or in equity arising out of or relating
                  to these Terms, this Website/Application or its Content shall
                  be filed only in the state or federal courts in Nigeria and
                  you hereby consent and submit to the personal jurisdiction of
                  such courts for the purposes of litigating any such action,
                  waive any objection to the laying of venue in such courts, and
                  agree not to plead or claim in any Nigeria court that such
                  litigation brought therein has been brought in an inconvenient
                  forum. All disputes relating to this Website/Application or
                  its Content shall be resolved individually, without resort to
                  any form of class action.
                </p>
                <h5>Severability</h5>
                <p>
                  The provisions of these Terms are intended to be severable. If
                  for any reason any provision of these Terms shall be unlawful,
                  void, or for any reason unenforceable, then that provision
                  shall be deemed severed from these Terms and shall not affect
                  the validity and enforceability of any remaining provisions.
                </p>
                <h5>Entire Agreement.</h5>
                <p>
                  The Terms represent the entire agreement between you and
                  Shelta relating to the subject matter herein and shall not be
                  modified except as provided herein or in writing signed by
                  both parties. Any rights not expressly granted herein are
                  reserved.
                </p>

                <button data-dismiss="modal" class="btn tc-btn">Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Terms and condition ends -->

      <!-- Footter bigins -->

      <!-- Footer ends -->
    </div>
    <!-- Footter bigins -->
    <Footer />
    <!-- Footer ends -->
  </div>
</template>

<script>
import Footer from "./FooterMain2.vue";
// import AuthNavbar from "./AuthNavbar";
import {
  required,
  minLength,
  maxLength,
  between
} from "vuelidate/lib/validators";
import { Service } from "../store/service";
const Api = new Service();

export default {
  name: "Signup",
  components: {
    Footer
    // AuthNavbar
  },
  data() {
    return {
      loader: false,
      userType: "individual",
      errors: [],
      message: "",
      message2: "",
      email: "",
      password: "",
      confirmPassword: "",
      ispartner: "NO",
      fname: "",
      lname: "",
      refereecode: ""
    };
  },
  validations: {
    email: {
      required,
      minLength: minLength(3),
      maxLegth: maxLength(10)
    }
  },
  computed: {
    isComplete() {
      return this.email && this.password && this.confirmPassword;
    }
  },
  methods: {
    // validateEmail() {
    //   const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   if (emailRe.test(this.email)) {
    //     this.wrongEmail = false;
    //     this.checkEmailExist();
    //   } else {
    //     this.wrongEmail = true;
    //   }
    // },
    signup: function() {
      this.loader = true;
      this.errors = [];
      const validRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!validRegex.test(this.email)) {
        this.errors.push("Invalid Email address!");
        this.loader = false;
      }
      if (this.fname === "" && this.userType == "organisation") {
        this.errors.push("Organization name is required!");
        this.loader = false;
      }
      if (this.fname === "" && this.userType == "individual") {
        this.errors.push("First name is required!");
        this.loader = false;
      }
      if (this.lname === "" && this.userType == "individual") {
        this.errors.push("Last name is required!");
        this.loader = false;
      }
      if (this.email === "") {
        this.errors.push("Email is required!");
        this.loader = false;
      }
      if (this.password === "") {
        this.errors.push("Password is required!");
        this.loader = false;
      }
      // if (this.code === "") {
      //   this.errors.push("Invite code is required!");
      //   this.loader = false;
      // }
      if (this.confirmPassword === "") {
        this.errors.push("Confirm password is required!");
        this.loader = false;
      } else if (this.password != this.confirmPassword) {
        this.errors.push("Passwords don't match");
        this.loader = false;
      } else {
        Api.postRequestNoAuth("register", {
          email: this.email,
          password: this.password,
          accounttype: this.userType,
          ispartner: this.ispartner,
          firstname: this.fname,
          lastname: this.lname,
          refereecode: this.refereecode
        })
          .then(res => {
            // console.log("Resgister Response", res);
            this.loader = false;
            if (res.data.success) {
              localStorage.setItem("shelta", res.data.token);
              localStorage.setItem(
                "shelta-user",
                JSON.stringify(res.data.user)
              );
              this.$router.push({
                path: `/verification`
              });
              return this.$store.commit("setApiSuccess", "Success");
              // this.$router.push(this.$router.currentRoute.query.redirect);
            } else if (res.data.error) {
              this.loader = false;
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log("err", err);
            this.loader = false;
          });
      }
    },
    // Password visibilty
    toggleLock: function() {
      const element = document.querySelector(".password-icon");
      this.shown = !this.shown;
      if (this.shown) {
        document.getElementsByName("password")[0].setAttribute("type", "text");
        element.classList.remove("fa-lock");
        element.classList.add("fa-unlock");
      } else {
        document
          .getElementsByName("password")[0]
          .setAttribute("type", "password");
        element.classList.remove("fa-unlock");
        element.classList.add("fa-lock");
      }
    },
    // Confirm Password visibilty2
    toggleLock2: function() {
      const element = document.querySelector(".password-icon2");
      this.shown = !this.shown;
      if (this.shown) {
        document
          .getElementsByName("confirmPassword")[0]
          .setAttribute("type", "text");
        element.classList.remove("fa-lock");
        element.classList.add("fa-unlock");
      } else {
        document
          .getElementsByName("confirmPassword")[0]
          .setAttribute("type", "password");
        element.classList.remove("fa-unlock");
        element.classList.add("fa-lock");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$black: #000000;
// Gotham: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: Gotham;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

::placeholder {
  font-family: Gotham;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.supper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.holder {
  flex-grow: 1;
  // min-height: 100vh;
}
.logo_holder {
  margin-bottom: 1rem;
}
.logo-div {
  background: $primary;
  // clip-path: circle(50% at 50% 0);
  clip-path: circle(50% at 50% -20%);
  margin: auto;
  width: 12rem;
  height: 7rem;
  height: 4rem;
  position: relative;
}
.logo-div > img {
  width: 4.5rem;
  position: relative;
  top: 23%;
}

.orgInd {
  display: flex;
  background: #f0f3fe;
  border-radius: 8.91px;
  margin-bottom: 35px;
  button {
    background: #f0f3fe;
    border-radius: 8.91px;
    height: 45px;
    margin: 0;
    box-shadow: none;
    outline: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #767676;
  }
}

.active_btn {
  background: $primary !important;
  color: $white !important;
}

form {
  display: inline-block;
}
.form-group {
  max-width: 608px;
  width: 100%;
  label {
    display: flex;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 124%;
    color: #57595a;
  }
}
.form-control {
  box-shadow: none;
  outline: 0;
  height: 44px;
}

// .my-card-body {
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
// }
.login-header {
  h1 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #1e1e1e;
  }
  p {
    margin-top: 10px;
    margin-bottom: 30px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    color: #1e1e1e;
  }
}

.login-body {
  position: relative;
  padding-top: 1rem;
}

.login-body > div {
  // width: 25rem;
  margin: auto;
}

.login-btn {
  background-color: $primary;
  color: #ffffff;
  text-transform: uppercase;
  box-shadow: none;
  outline: 0;
  height: 45px;
}

.p-checkbox {
  text-align: left;
  label {
    // text-align: left;
    // font-family: Gotham;
    // font-style: normal;
    // font-weight: 600;
    // font-size: 14px;
    // line-height: 125%;
    padding-left: 10px;
    // color: $offblack;
  }
}
// .checkboxlabel {
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 15px;
//   line-height: 25px;
//   letter-spacing: -0.05em;
//   color: #000000;
// }
.checkboxlabel ::after {
  cursor: pointer !important;
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-top: 0;
}

.or {
  display: flex;
  justify-content: center;
  align-items: center;
  hr:first-child {
    width: 100%;
    margin-right: 20px;
  }
  hr:last-child {
    width: 100%;
    margin-left: 20px;
  }
  span {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    color: #1e1e1e;
  }
}

.google_btn {
  border: 1px solid #8e908e;
  border-radius: 6px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  height: 45px;
  // line-height: 124%;
  text-align: center;
  color: #1e1e1e;
  img {
    padding-right: 10px;
  }
}

.email-group {
  position: relative;
}
.email-icon {
  font-size: 0.9rem !important;
  position: absolute !important;
  bottom: 6% !important;
  right: 2% !important;
}
.password-icon::before,
.password-icon2::before {
  cursor: pointer;
}

.password-group {
  position: relative;
}
.password-icon {
  font-size: 0.9rem !important;
  position: absolute !important;
  bottom: 6% !important;
  right: 2% !important;
}

.password-group2 {
  position: relative;
}
.password-icon2 {
  font-size: 0.9rem !important;
  position: absolute !important;
  bottom: 6% !important;
  right: 2% !important;
}

.terms-conditions > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  align-items: flex-end;
  color: $primary;
}
.have-account {
  display: flex;
  justify-content: center;
  padding-top: 0.6rem;
}
.have-account {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 12px;
    color: #000000;
  }
}

.register-span {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 12px;
  // color: #000000;
  color: $primary;
  cursor: pointer;
}

.login-router {
  color: #000000;
  text-decoration: none;
}

// .login-wrap {
//   display: flex;
// }

.whatsapp-dialer-icon-holder {
  position: absolute;
  right: 37%;
  right: 51%;
}
.whatsapp-dialer-icon-holder > img:first-child {
  position: relative;
  bottom: 18rem;
  left: 44rem;
  cursor: pointer;
}
.whatsapp-dialer-icon-holder > img:nth-child(2) {
  position: relative;
  bottom: 14rem;
  left: 42.4rem;
  cursor: pointer;
}

.errorHeader {
  font-family: Gotham;
  font-weight: normal;
  font-size: 17px;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  // margin-left: 1em;
}
.errorMessage {
  font-family: Gotham;
  font-weight: normal;
  color: #dc3545;
  font-size: 14px;
  list-style: none;
  text-align: center;
  padding-inline-start: 0;
}
.error-ul {
  padding-inline-start: 0;
}
.btn-loader {
  padding: 0 !important;
  font-size: 17px !important;
  margin-left: 2rem !important;
}

// Footer section begins
.footer-holder {
  position: relative;
}
.fa {
  padding: 20px;
  font-size: 30px;
  width: 13.55px !important;
  height: 13.55px !important;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  position: relative;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  // background: #3b5998;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-facebook::before {
  position: absolute;
  left: 38%;
  top: 30%;
  font-size: 1.4rem;
}

.fa-twitter {
  // background: #55acee;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-twitter::before {
  position: absolute;
  left: 30%;
  top: 28%;
  font-size: 1.4rem;
}

.fa-linkedin {
  // background: #007bb5;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-linkedin::before {
  position: absolute;
  left: 30%;
  top: 25%;
  font-size: 1.4rem;
}

.fa-instagram {
  // background: #125688;
  border: 1px solid #fff;
  color: white;
  border-radius: 3px;
}
.fa-instagram::before {
  position: absolute;
  left: 30%;
  top: 25%;
  font-size: 1.4rem;
}
// .clip-shape-div {
//   clip-path: ellipse(43% 164% at 50% 164%);
//   // clip-path: ellipse(35% 164% at 50% 165%);
//   background: $primary;
//   // position: relative;
// }

.copy-right-text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  margin-bottom: 0;
  color: #ffffff;
  padding-bottom: 0.5rem;
}

.help-holder {
  position: absolute;
  right: 2%;
  bottom: 8%;
  width: 89px;
  height: 45px;
  background: $primary;
  border-radius: 22.5px;
}
// .quest-mark {

// }
// Footer section ends

// Terms and condtions
.tc {
  color: $yellow;
  font-weight: bold;
  cursor: pointer;
}
.terms-holder {
  padding: 24px;
  h4 {
    font-family: Gotham;
    font-weight: 600;
    font-size: 20px;
  }
  h6 {
    font-family: Gotham;
    text-align: left;
    line-height: 25px;
    font-size: 15px;
    margin-bottom: 20px;
  }
}
.terms-body {
  h5 {
    font-family: Gotham;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  p {
    text-align: left;
    font-size: 15px;
    font-family: Gotham;
  }
  .tc-btn {
    background: $primary;
    color: $white;
    font-family: Gotham;
    font-size: 16px;
  }
}
// Terms and condtions

@media screen and (min-device-width: 320px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  // .login-body {
  //   padding-top: 3rem;
  // }
  // .whatsapp-dialer-icon-holder {
  //   // position: relative;
  //   // right: 22%;

  //   right: 45%;
  //   justify-content: center;
  //   margin-bottom: 2rem;
  //   padding-top: 0.6rem;
  // }
  // .whatsapp-dialer-icon-holder > img:first-child {
  //   bottom: 1rem;
  //   left: 7px;
  // }
  // .whatsapp-dialer-icon-holder > img:nth-child(2) {
  //   bottom: 1rem;
  //   left: 9px;
  // }
  .footer-holder {
    padding-top: 3.2rem;
  }
  // .help-holder {
  //   left: 36%;
  //   top: 23%;
  //   position: absolute;
  // }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  // .login-body > div {
  //   max-width: 29.02625rem !important;
  // }
  .mobile-logo-holder {
    display: none;
  }
}
@media only screen and (max-width: 599px) {
  .supper {
    min-height: 100%;
  }
  .mobile-logo-holder {
    margin: auto;
    height: 4rem;
    position: relative;
  }
  .login-header {
    p {
      text-align: left;
    }
  }
  .blue-back-logo > img {
    width: 18.3016105417vw;
  }
  .shelta-mobile-logo {
    position: absolute;
    top: 5%;
    left: 46%;
  }
  .shelta-mobile-logo > img {
    width: 8.7115666179vw;
  }
}
</style>
